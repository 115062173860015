// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT
const sentryDSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const release = process.env.SENTRY_RELEASE || process.env.NEXT_PUBLIC_SENTRY_RELEASE

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: sentryDSN,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps,
    environment,
    ignoreErrors: [/vid_mate_check/, /is_mark_able_element/],
    release,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.01,
  })
}
