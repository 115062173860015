import '../styles/site.styles.css'

import { AppProps } from 'next/dist/shared/lib/router/router'
import Head from 'next/head'
import { useEffect } from 'react'

import FBQ from '../components/analytics/FBQ'
import Gtag from '../components/analytics/Gtag'
import { version } from '../configs/version'
import useDeviceId from '../hooks/cookies/useDeviceId'

const HealofyApp = (ctx: AppProps) => {
  useEffect(() => {
    if (localStorage) {
      localStorage.setItem('v', version)
    }
  })

  useDeviceId()

  return (
    <>
      <Head>
        <link href='/manifest.json' rel='manifest' />
        <link href='/favicon.ico' rel='shortcut icon' />
        <link href='/apple-touch-icon-precomposed.png' rel='apple-touch-icon-precomposed' />
        <link href='/apple-touch-icon.png' rel='apple-touch-icon' />
        <meta name='theme-color' content='#ff74d3' />
        <meta name='facebook-domain-verification' content='e0dceh92y0n3pwmxqqgs0koyqz1lpt' />
      </Head>
      <FBQ />
      <Gtag />
      <ctx.Component {...ctx.pageProps} />
    </>
  )
}

export default HealofyApp
