import * as Sentry from '@sentry/nextjs'
import { CaptureContext, ScopeContext } from '@sentry/types'

import { ErrorWithData } from '../../interfaces'
import { getDeviceId } from '../../utils/deviceId'
import * as log from '../../utils/log'

const ignoreErrors = new Set<string>()

export const captureException = <K>(err: ErrorWithData<K>, ctxName: string, captureContext?: CaptureContext) => {
  if (ignoreErrors.has(ctxName)) {
    log.warn(err, 'will not send error to sentry')
    return
  }
  try {
    const extra = { ...((captureContext as ScopeContext)?.extra || {}), deviceId: getDeviceId() }
    return Sentry.captureException(err, { ...captureContext, extra })
  } catch (error) {
    log.error(error, 'unable to send data to sentry')
  }
}
