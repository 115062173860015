import { useEffect } from 'react'

import { getDeviceId } from '../../utils/deviceId'

const useDeviceId = () => {
  useEffect(() => {
    getDeviceId()
  })
}

export default useDeviceId
