import Script from 'next/script'

const gTagId = process.env.NEXT_PUBLIC_GTAG_CODE
const adWordsId = process.env.NEXT_PUBLIC_GADWORDS_CODE

const Gtag = () => (
  <>
    <Script async id='gtagLoader' src={`https://www.googletagmanager.com/gtag/js?id=${gTagId}`} />
    <Script id='gtagDataLayer'>
      {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gTagId}');
        ${adWordsId ? `gtag('config', '${adWordsId}');` : ''}`}
    </Script>
  </>
)

export default Gtag
