import Cookies from 'universal-cookie'

// import KSUID from 'ksuid'
import { captureException } from '../api/analytics/sentry'
import { deviceIdKey } from '../configs'
import { contextNames } from '../data/errors'
import { ErrorWithData } from '../interfaces'

const future = new Date(2030, 12, 31)

const createId = () => {
  const now = Date.now()
  const r = Math.floor(Math.random() * 1_000_000_000)
  return now.toString(36) + r.toString(36)
}

export const getDeviceId = () => {
  if (typeof window === 'undefined') {
    return 'nowindow'
  }

  try {
    const cookies = new Cookies()
    const id = cookies.get(deviceIdKey)
    if (id) {
      return id
    }

    const newId = createId()
    cookies.set(deviceIdKey, newId, { expires: future, path: '/', sameSite: true, secure: true })
    return newId
  } catch (error) {
    const id = createId()
    captureException(error as ErrorWithData<undefined>, contextNames.deviceId)
    return id
  }
}
